/* eslint-disable no-use-before-define */
import { DOCUMENT } from '@angular/common';
import {
  afterRender,
  ElementRef,
  inject,
  Injector,
  Renderer2,
  RendererFactory2,
  runInInjectionContext,
} from '@angular/core';
import { mimeTypesWhichCanBeNativelyOpenedInBrowser } from '../models/global.model';
import { catchError, finalize, Observable, of, take, throwError } from 'rxjs';

type Entry<T> = [keyof T, T[keyof T]];

// Typed Object.entries function
export function objectEntries<T extends object>(obj: T): Entry<T>[] {
  return (Object.keys(obj) as Array<keyof T>).map((key) => [
    key,
    obj[key],
  ]) as Entry<T>[];
}

export function contains(text: string, filter: string): boolean {
  return getContainsMatchIndexPosition(text, filter) !== -1;
}

export function getContainsMatchIndexPosition(
  text: string,
  filter: string
): number {
  text = text.toLocaleLowerCase();
  filter = filter.toLocaleLowerCase();
  return text.indexOf(filter);
}

export function setSortByDirection<T>(
  fieldName: keyof T,
  direction?: 'asc' | 'desc'
): string {
  return !direction || direction === 'asc'
    ? String(fieldName)
    : `-${String(fieldName)}`;
}

export function formatBytes(bytes: number, decimals: number = 2): string {
  if (!+bytes) {
    return '0 Bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function convertBreakPointToUnit(breakpoint: number, unit: 'px' | 'em') {
  return unit === 'px' ? breakpoint + unit : breakpoint / 16 + unit;
}

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export function deepEqual(valueA: any, valueB: any): boolean {
  if (Object.is(valueA, valueB)) {
    return true;
  }

  if (valueA instanceof Date && valueB instanceof Date) {
    return valueA.getTime() === valueB.getTime();
  }

  const bothObjects =
    Object.prototype.toString.call(valueA) === '[object Object]' &&
    Object.prototype.toString.call(valueB) === '[object Object]';

  const bothArrays = Array.isArray(valueA) && Array.isArray(valueB);

  if (!bothObjects && !bothArrays) {
    return false;
  }

  if (Object.keys(valueA).length !== Object.keys(valueB).length) {
    return false;
  }

  for (const key in valueA) {
    if (!deepEqual(valueA[key], valueB[key])) {
      return false;
    }
  }

  return true;
}

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export function isEmpty(value: any): boolean {
  if (value === null || value === '' || value === undefined) {
    return true;
  }

  if (Array.isArray(value)) {
    for (const item of value) {
      if (!isEmpty(item)) {
        return false;
      }
    }
    return true;
  }

  if (typeof value === 'object') {
    for (const v of Object.values(value)) {
      if (!isEmpty(v)) {
        return false;
      }
    }
    return true;
  }

  return false;
}

export function openOrDownloadFileInNewBrowserTab(
  blob: Blob,
  injector: Injector,
  filename: string = 'noname'
): void {
  openOrDownloadFileInBrowserTab(blob, injector, filename, true);
}

export function openOrDownloadFileInBrowserTab(
  blob: Blob,
  injector: Injector,
  filename: string = 'noname',
  openInNewTab: boolean = false
): void {
  // Inject services
  const factory = runInInjectionContext(injector, () =>
    inject(RendererFactory2)
  );
  const renderer: Renderer2 = factory.createRenderer(null, null);
  const document: Document = runInInjectionContext(injector, () =>
    inject(DOCUMENT)
  );

  const window = document.defaultView;

  if (!window) {
    throw new Error('global window object is not defined');
  }

  const url = window.URL.createObjectURL(blob);
  const a = renderer.createElement('a');
  renderer.setAttribute(a, 'href', url);
  if (openInNewTab) {
    renderer.setAttribute(a, 'target', '_blank');
  }
  if (!mimeTypesWhichCanBeNativelyOpenedInBrowser.includes(blob.type)) {
    renderer.setAttribute(a, 'download', filename);
  }

  renderer.appendChild(document.body, a);
  a.click();
  renderer.removeChild(document.body, a);
}

export function filterListOfObjectsWithFilterText<T extends object>(
  list: T[],
  filter: string,
  propertiesToBeFiltered: Array<keyof T>
): T[] {
  return list.filter((obj: T) =>
    isObjectMatchesFilter(obj, filter, propertiesToBeFiltered)
  );
}

function isObjectMatchesFilter<T extends object>(
  obj: T,
  filter: string,
  propertiesToBeFiltered: Array<keyof T>
): boolean {
  return propertiesToBeFiltered.some((field) =>
    contains((obj[field] as string) || '', filter)
  );
}

// Waits when specific element is rendered to the DOM
// then calculates it's position on the screen and
// applies callback function to do whatever you need
// with the calculated position of the element.
//
// NOTE: use it carefully!!!
//       1) Always check that your code reach this line:
//       afterRenderRef.destroy();
//       Otherwise this function will be called on
//       every render to the screen
//       2) Should be called from the constructor
export function calculateElementPosition(
  waitForElementRefFunction: () => ElementRef | undefined,
  callback: (dimensions: DOMRect) => boolean
) {
  const afterRenderRef = afterRender(() => {
    const element = waitForElementRefFunction();

    if (!element) {
      return;
    }
    const nativeElement = element.nativeElement;

    if (!(nativeElement instanceof Element)) {
      console.warn('selected element is not instance of Element');
      return;
    }

    const bounds = nativeElement.getBoundingClientRect();

    if (callback(bounds)) {
      afterRenderRef.destroy();
    }
  });
}

export interface PollingController {
  start: () => void;
  stop: () => void;
  changeCycleOnTheFly: (cycle: number | ((cycle: number) => number)) => void;
}

/**
 * Function creates an object which has methods to control polling.
 * When you use "start" method of that object, polling will start
 * with specified "cycleInMs". If you want to stop polling for some
 * reason, you can call "stop" method. Nothing prevents you then to
 * use "start" method again to resume the same polling. Also there is
 * functionality to change cycleInMs during runtime by calling method
 * "changeCycleOnTheFly".
 *
 * There are multiple "poll" function signatures (potentially will be
 * more):
 *  1) "whatToPoll" can be either Observable or function.
 *  2) Based on "whatToPoll" type, the other parameter "options" has
 *     different set of options:
 *      if "whatToPoll" is Observable - then we can give capability
 *      to wait when Observable emits value by using "waitForCompletion"
 *      option and only then allow to run another poll cycle. It helps
 *      to prevent if we have short cycle periods but Observable we
 *      provide can take more time than poll cycle.
 *      The other option is "stopOnError" available for every type
 *      of "whatToPoll" (either Observable or function). As the name
 *      suggest it will stop polling if error will be raised by
 *      Observable or function
 */
export function poll(
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  whatToPoll: Observable<any>,
  cycleInMs: number,
  options?: {
    waitForCompletion?: boolean;
    stopOnError?: boolean;
  }
): PollingController;
export function poll(
  whatToPoll: () => void,
  cycleInMs: number,
  options?: {
    stopOnError?: boolean;
  }
): PollingController;
export function poll(
  whatToPoll: unknown,
  cycleInMs: number,
  options?: {
    waitForCompletion?: boolean;
    stopOnError?: boolean;
  }
): PollingController {
  let shouldStop = false;
  let isRun = false;
  let currentCycle = cycleInMs;

  let functionToRun: () => void;

  if (whatToPoll instanceof Observable) {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    functionToRun = function (this: any) {
      if (options?.waitForCompletion === true && isRun === true) {
        return;
      }

      isRun = true;

      whatToPoll
        .pipe(
          catchError((error) => {
            if (options?.stopOnError === true) {
              shouldStop = true;
            }
            console.error(error);
            return of();
          }),
          finalize(() => {
            isRun = false;
          })
        )
        .subscribe();
    };
  } else if (typeof whatToPoll === 'function') {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    functionToRun = function (this: any) {
      try {
        whatToPoll.call(this);
      } catch (error) {
        console.error(error);
        if (options?.stopOnError === true) {
          shouldStop = true;
        }
      }
    };
  } else {
    throw 'unknown set of parameters';
  }

  function loop() {
    window.setTimeout(() => {
      if (shouldStop === false) {
        functionToRun();
        loop();
      }
    }, currentCycle);
  }

  return {
    start: () => {
      shouldStop = false;
      loop();
    },
    stop: () => {
      shouldStop = true;
    },
    changeCycleOnTheFly(cycle: number | ((cycle: number) => number)) {
      if (typeof cycle === 'number') {
        currentCycle = cycle;
        return;
      }
      currentCycle = cycle(currentCycle);
    },
  };
}

/**
 * Simple debounce function which wraps another function provided
 * as a parameter and returns new function which will be called
 * only after specified "delay".
 *
 * Usage example:
 *
 *   function originalFunction(randomText: string) {
 *      console.log(randomText);
 *   }
 *
 *   const debouncedFunction = debounce(originalFunction, 2000);
 *
 *   debouncedFunction('a');
 *   debouncedFunction('b');
 *   debouncedFunction('c');
 *
 *   logs only 'c' after 2000ms
 *
 */
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export function debounce<T extends (...args: any[]) => any>(
  inputFunc: T,
  delay: number
): (...args: Parameters<T>) => void {
  let timeoutId: number;
  return function (this: ThisParameterType<T>, ...args: Parameters<T>) {
    if (timeoutId) {
      window.clearTimeout(timeoutId);
    }

    timeoutId = window.setTimeout(() => {
      inputFunc.apply(this, args);
    }, delay);
  };
}

export function isStringRepresentsNumber(value: string): boolean {
  const convertedValue = +value;
  return !isNaN(convertedValue) && typeof convertedValue === 'number';
}

export function updateData<T extends object>(
  target: T[],
  source: T[],
  uniqueFieldName: keyof T,
  mode: 'full' | 'update-only' = 'update-only',
  preserveItemsPosition: boolean = true
): void {
  if (mode === 'full' && preserveItemsPosition === false) {
    target = structuredClone(source);
  } else if (mode === 'full' && preserveItemsPosition === true) {
    // Implement on demand
  } else if (mode === 'update-only') {
    // Updates only (not new records added, no removal).
    // Like "target LEFT JOIN source"
    for (let i = 0; i < target.length; i++) {
      let targetItem = { ...target[i] };

      const sourceItem = source.find(
        (item) => item[uniqueFieldName] === targetItem[uniqueFieldName]
      );

      if (!sourceItem || deepEqual(sourceItem, targetItem)) {
        continue;
      }

      targetItem = { ...sourceItem };
      target[i] = targetItem;
    }
  }
}

/**
 * In APP we have some APIs which provide cursor based pagination.
 * Such APIs use "continuationToken" field name for the cursor.
 * This field can be passed to API, and in response we can get
 * the same field but with different value. Since it is common
 * pattern for the API, this function was created which recursively
 * and sequentially makes API calls unless API returns
 * continuationToken = null. When we get null, it stops recursive
 * calls.
 *
 * @req function which makes API call and returns Observables with
 * data including continuationToken field. Function also can take
 * parameters which will be translated to query parameters of the
 * request
 * @params parameters of the first call, every following recursive
 * calls will be using this parameters except "continuationToken"
 * because it will be replaced with response value of the previous
 * recursive call in a stack.
 * @returns Observable which emits response for every API call in
 * the order of the calls.
 */
export function loadCursorBasedApi<
  R extends { continuationToken?: string | null | undefined },
  P extends { continuationToken?: string },
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
>(this: any, req: (params: P) => Observable<R>, params: P) {
  return new Observable<R>((subscriber) => {
    helper.call(this, params);
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    function helper(this: any, params: P) {
      return req
        .call(this, params)
        .pipe(
          catchError((err) => {
            subscriber.error(err);
            return throwError(() => err);
          }),
          take(1)
        )
        .subscribe((response) => {
          subscriber.next(response);
          if (!!response.continuationToken) {
            helper.call(this, {
              ...params,
              continuationToken: response.continuationToken,
            });
          } else {
            subscriber.complete();
          }
        });
    }
  });
}
